<template>
	<div class="mainBox">
		<div class="logo"><img src="../assets/logo.png" style="width: 30%;" /></div>
		<div class="formBox">
			<van-form>
				<div class="title"><img src="../assets/title.png" style="width: 50%;" /></div>
				<van-cell-group inset>
					<van-field v-model="username" name="username" label="主人公姓名" placeholder="請輸入男生或女生姓名" type="text"
						clearable label-width="90px" left-icon="contact-o" />
					<van-field v-model="idcard" name="idcard" label="主人公證件" placeholder="請輸入對應身份證號碼" type="text"
						clearable label-width="90px" left-icon="idcard" pattern="[0-9Xx]{18}" maxlength="18" />
					<!-- <van-field v-model="code" name="code" label="簡訊驗證碼" placeholder="請輸入驗證碼" maxlength="4"
						left-icon="comment-o"> <template #button>
							<van-button size="mini" type="primary" plain color="#000000" @click="handleSendMsg"
								:loading-text="btnText" :loading="loading"
								loading-size="10">{{btnText}}</van-button></template></van-field> -->
				</van-cell-group>
				<div style="margin: 16px;">
					<van-button round block type="primary" native-type="submit" color="#000000" @click="cert"
						:loading="loading2">
						査詢證書
					</van-button>
					<div style="margin-top: 20px;text-align: center;color: #868686;font-size: 11px;">
						两人一證，終身綁定，永不刪改</div>
				</div>
			</van-form>
		</div>
		<Vcode :show="isShow" @success="onSuccess" @close="onClose" @reset="onReset" failText="驗證失敗，請重試"
			sliderText="拖動滑塊完成拼圖" successText="驗證成功,正在申請驗證碼" canvasWidth="230" canvasHeight="180" />
		<div class="footer">&copy; 2024-2025 Design By VernonShao<div class="istero" @click="toistero">申請證書</div>
		</div>
	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		verifyCert,
		sendCode
	} from '@/api/home';
	import Vcode from "vue3-puzzle-vcode";
	import {
		showDialog,
		showNotify,
		showToast,
		showLoadingToast,
		closeToast
	} from 'vant';
	import {
		ref
	} from "vue";
	export default {
		name: 'App',
		components: {
			Vcode,
		},
		data() {
			return {
				card: "",
				password: "",
				code: "",
				disabled: false,
				remainingTime: 60,
				message: "",
				show: false,
				isShow: false,
				username: "",
				idcard: "",
				banner: "",
				btnText: "發送驗證碼",
				loading: false,
				loading2: false,
				timer: null // 存储定时器ID
			}
		},
		created() {
			

		},
		setup() {
			const router = useRouter()
			const route = useRoute()


			pushWithQuery()

			function pushWithQuery() {
				// if (sessionStorage.getItem("access_token")) {
				// 	router.push({
				// 		path: "/cert"
				// 	})
				// }

			}
		},
		methods: {
			onSuccess() {
				let that = this
				that.isShow = false
				// showLoadingToast({
				// 	message: '信息校驗中',
				// 	forbidClick: true,
				// 	duration: 15000
				// });
				that.verCert()
			},
			onClose() {
				let that = this
				that.isShow = false
			},
			onShow() {
				let that = this
				that.isShow = true
			},
			validator(val) {
				const card15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/
				const card18 =
					/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
				return card15.test(val) || card18.test(val)
			},
			toistero() {
				//window.location = 'https://www.istero.com'
				showDialog({
					title: '提示',
					message: "系統內測中，暫不對外開放"
				});
			},
			onReset() {
				showNotify({
					type: 'primary',
					background: '#000000',
					message: '拼圖已刷新',
					duration: 1000
				});
			},
			handleSendMsg() {
				let that = this
				if (!that.username) {
					showNotify({
						type: 'primary',
						background: '#000000',
						message: '姓名不能為空',
						duration: 1000
					});
					return false
				} else if (!that.idcard) {
					showNotify({
						type: 'primary',
						background: '#000000',
						message: '身份證號碼不能為空',
						duration: 1200
					});
					return false
				} else if (!that.validator(that.idcard)) {
					showNotify({
						type: 'primary',
						background: '#000000',
						message: '身份證號碼格式錯誤',
						duration: 1200
					});
					return false
				} else {
					that.isShow = true
				}
			},
			verCert() {
				let that = this
				that.loading2 = true
				verifyCert({
					username: that.username,
					idcard: that.idcard,
					// code: that.code
				}).then(res => {
					that.loading2 = false
					if (res.code == 200) {
						that.$router.push({
							path: "/cert",
						})
					}
				})
			},
			sendCode() {
				let that = this
				sendCode({
					username: that.username,
					idcard: that.idcard
				}).then(res => {
					if (res.code == 200) {
						closeToast();
						showNotify({
							message: '驗證碼已發送至綁定手機',
							type: 'success',
							duration: 3500
						});
						that.countDown()
					} else {
						that.loading = false
					}

				})

			},
			countDown() {
				let that = this
				that.loading = true
				that.btnText = "60 秒"
				that.timer = setInterval(() => {
					if (that.remainingTime > 1) {
						that.remainingTime--;
						that.btnText = that.remainingTime + " 秒"
					} else {
						clearInterval(that.timer);
						that.remainingTime = 60
						that.btnText = "發送驗證碼"
						that.loading = false


					}
				}, 1000);
			},


			cert() {
				let that = this
				if (!that.username) {
					showNotify({
						type: 'primary',
						background: '#000000',
						message: '姓名不能為空',
						duration: 1000
					});
					return false
				} else if (!that.idcard) {
					showNotify({
						type: 'primary',
						background: '#000000',
						message: '身份證號碼不能為空',
						duration: 1200
					});
					return false
				} else if (!that.validator(that.idcard)) {
					showNotify({
						type: 'primary',
						background: '#000000',
						message: '身份證號碼格式錯誤',
						duration: 1200
					});
					return false
				}
				// else if (!that.code) {
				// 	showNotify({
				// 		type: 'primary',
				// 		background: '#000000',
				// 		message: '請輸入驗證碼',
				// 		duration: 1000
				// 	});
				// 	return false
				// } 
				else {
					that.isShow = true
				}
			}
		}


	}
</script>
<style>
	body {
		background-image: url("../assets/bg.jpg");
		background-size: 100% 100%;
		min-height: 100vh;
		/* 视口高度 */
		/* background-size: cover; */
		/* 背景图片覆盖整个元素 */
		background-position: center;
		/* 背景图片居中 */
		background-repeat: no-repeat;
		/* 背景图片不重复 */
		width: 100%;
		/* background-attachment: fixed; */
		overflow: hidden;
	}

	.mainBox {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		margin-top: 50px;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 20px 10px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;

	}

	.mainBox .feedback {
		position: absolute;
		right: 0px;
		top: 10%;
		background: #a98146;
		padding: 5px 10px;
		font-size: 12px;
		border-radius: 10px 0px 0px 10px;
	}

	.mainBox .logo {
		padding: 5px 0px;
	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #000000;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.mainBox .btnBox {
		width: 95%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
</style>